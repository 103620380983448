<template>
  <v-container fill-height>
    <v-row align="center" justify="center" class="text-center">
      <v-col>
        <h1>404</h1>
        <h1><strong>The Page you are looking for is not available</strong></h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Page404",
};
</script>
